import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";


/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import { lazy, Suspense, useRef } from "react";
import { Logo } from "./components/Logo";
import { bikeCollections } from "./components/BikeCollections";
import { carCollections } from "./components/CarCollection";
import { hotelCollections } from "./components/HotelCollections";
import { travelCollections } from "./components/TravelCollections";
import UploadBikes from "./pages/UploadBikes";

const Home = lazy(() => import("./pages/Home"));

setupIonicReact();

const App: React.FC = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  const router = (
    <IonRouterOutlet>
      <Route exact path="/visakha-rentals" render={() => <Home title="BIKE ON RENT IN Vizag" type="bikes" collections={bikeCollections} />}>

      </Route>
      <Route exact path="/car-rental-in-vizag" render={() => <Home title="CAR ON RENT IN Vizag" type="cars" collections={carCollections} />}>

      </Route>

      <Route exact path="/taxi-rental-in-vizag" render={() => <Home title="Taxi and Travells" type="hotels" collections={travelCollections} />}>

      </Route>
      <Route path="/hotel-booking-in-vizag-chalo-ride">
        <Home title="Hotel Collectons" type="hotels" collections={hotelCollections} />
      </Route>
      <Route path={"/upload"} render={() => <UploadBikes />} />
      <Route exact path="/">
        <Redirect to="/visakha-rentals" />
      </Route>
    </IonRouterOutlet>
  );
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };
  return (
    <IonApp>
      <IonContent ref={contentRef} scrollEvents={true}>
        <Suspense fallback={'Loading...'}>
          <IonReactRouter>
            <IonTabs>
              {router}
              <IonTabBar
                style={{ height: "60px" }}
                slot={isMobileDevice ? "bottom" : "top"}

              >
                {!isMobileDevice && <IonTabButton
                  tab="/visakha-rentals"
                  href="/visakha-rentals"
                >
                  <Logo style={{
                    width: '400px',
                    padding: 0,
                    top: '6px',
                    left: 0
                  }} />
                </IonTabButton>}
                <IonTabButton
                  tab="/visakha-rentals"
                  href="/visakha-rentals"
                  onClick={() => scrollToTop()}
                >
                  <IonIcon
                    icon={"bicycle-outline"}
                    style={
                      isMobileDevice
                        ? { fontSize: "2.3rem", merginBottom: "5px" }
                        : {}
                    }
                  />
                  <IonLabel className="menu-size-increase">
                    Bikes
                  </IonLabel>
                </IonTabButton>
                <IonTabButton tab="car-rental-in-vizag" href="/car-rental-in-vizag">
                  <IonIcon
                    icon={"car-sport-outline"}
                    style={
                      isMobileDevice
                        ? { fontSize: "2rem", merginBottom: "5px" }
                        : {}
                    }
                  />
                  <IonLabel className="menu-size-increase">
                    Cars
                  </IonLabel>
                </IonTabButton>
                <IonTabButton tab="pickup-location" onClick={() => window.location.href = "https://wa.me/919912512579?text=Share Me Pickup Location"}>
                  <IonIcon
                    icon={"location-outline"}
                    style={
                      isMobileDevice
                        ? { fontSize: "2rem", merginBottom: "5px" }
                        : {}
                    }
                  />
                  <IonLabel className="menu-size-increase">Location</IonLabel>
                </IonTabButton>
                <IonTabButton tab="call-me-now" onClick={() => window.location.href = "tel:+919912512579"}>
                  <IonIcon
                    icon={"call-outline"}
                    style={
                      isMobileDevice
                        ? { fontSize: "2rem", merginBottom: "5px" }
                        : {}
                    }
                  />
                  <IonLabel className="menu-size-increase">
                    {isMobileDevice ? "Phone" : "Book via Call"}
                  </IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonReactRouter>
        </Suspense>
      </IonContent>
    </IonApp>
  );
};

export default App;
