export const bikeCollections = [
  {
    id: 1,
    name: "TVS Jupiter",
    category: "TVS, Jupiter,",
    filter: "scooty",
    image: "assets/bikes/jupitor-1.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 499 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 499 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
    exceed: 'if exceeds Rs.3/KM'
  },
  {
    id: 2,
    name: "Honda Dio",
    category: "scooty, honda, dio",
    filter: "scooty",
    image: "assets/bikes/Honda-Dio-3.png",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 499 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 499 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      
      {
        id: 5,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 3,
    name: "Honda Activa 5G",
    category: "scooty, activa, 5G",
    image: "assets/bikes/activaa-5g.jpeg",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 499 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 499 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 4,
    name: "Hero Xoom",
    category: "scooty, Hero, Xoom",
    image: "assets/bikes/3_Xoom_125_R_Revealed_1_2fc6024b70.png",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 5,
    name: "TVS Jupiter 125cc",
    category: "scooty, tvs, Jupiter",
    image: "assets/bikes/Jupiter-min.png",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 6,
    name: "Honda Activa 6G",
    category: "scooty, honda, 6g, 5g, 4g",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/activa-6g.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 6.2,
    name: "Honda Activa 125",
    category: "scooty, honda, 6g, 5g, 4g",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/activa-125-right-front-three-quarter.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Suzki Access 125cc",
    category: "scooty, Suzki, Access, 125",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/suzuki-gixxer-sf.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11.1,
    name: "Suzki Access 125cc BS6",
    filter: "scooty",
    category: "scooty, Suzki, Access, 125",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/SUZUKI_ACCESS_125_(2019).png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11.1,
    name: "TVS Ntorq 125",
    category: "scooty, TVS, Ntorq, 125",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/ntorq-125-right-side-view-11.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11.6,
    name: "Suzuki Avenis 125cc",
    category: "scooty, Suzuki, avenis, 125",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/avenis-GLOSSY-Sparkle-Black.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 7,
    name: "Hero ismart bs6",
    category: "bikes, Hero, ismart, BS6",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/Splendor-iSmart-BS6.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 8,
    name: "Hero Passion Pro BS6",
    category: "bikes, Hero, Passion, Pro, BS6",
    exceed: 'if exceeds Rs.5/KM',
    filter: "bike",
    image: "assets/bikes/hero-select-model-glaze-black-1622717908374.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 599 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 599 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 9,
    name: "Honda Shine BS6",
    category: "bikes, honda, Shine, bs6",
    exceed: 'if exceeds Rs.4/KM',
    filter: "bike",
    image: "assets/bikes/honda-cb-shine-bs6.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 749, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 749 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 749 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 749 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 749 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 5249,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 13999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 9.9,
    name: "Honda SP Shine BS6",
    category: "bikes, honda, Shine, bs6",
    filter: "bike",
    exceed: 'if exceeds Rs.4/KM',
    image: "assets/bikes/Honda-SP-Shine-125cc-sp.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 749, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 749 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 749 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 749 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 749 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 5249,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 13999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 889,
    name: "Honda Hornet BS4",
    category: "bikes, Honda, Hornet, BS4",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/honda-cb-hornet-160r-mars-orange.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 799, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 799 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 799 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 799 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 799 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 5249,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 13999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 88,
    name: "Avenger",
    category: "bikes, Avenger, bajaj, bs6",
    exceed: 'if exceeds Rs.5/KM',
    filter: "bike",
    image: "assets/bikes/6uzvcLqn.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 799, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 799 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 799 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 799 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 799 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 5249,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 13999,
        kms: "2,000 Kms",
      },
    ],
  },

  {
    id: 10,
    name: "Bajaj Pulsar 150cc",
    category: "bikes, Bajaj, Pulsar, 150cc",
    exceed: 'if exceeds Rs.5/KM',
    filter: "bike",
    image: "assets/bikes/pulser-123.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 799, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 799 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 799 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 799 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 799 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 5249,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 13999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Bajaj NS 125cc",
    category: "bikes, Bajaj, NS, 160cc",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/pulsarns160-right-side-view.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 899, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 899 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 899 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 899 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 899 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 6299,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 16799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 16,
    name: "Yamaha FZ",
    category: "bikes, Yamaha, FZ",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/YamahaFZ.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 899, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 899 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 899 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 899 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 899 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 6299,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 16799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 33,
    name: "Suzuki Gixxer 150",
    category: "bikes, Xpulse, hero",
    exceed: 'if exceeds Rs.5/KM',
    filter: "bike",
    image: "assets/bikes/2019-gixxer6433ed0f390de.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 999, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 999 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 999 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 999 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 999 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 6299,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 16799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 12,
    name: "Royal Enfield Classic 350cc",
    exceed: 'if exceeds Rs.5/KM',
    category: "bikes, Royal, Enfield, Classic, 350cc",
    filter: "bike",
    image: "assets/bikes/royal-enfield.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 1199, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 1199 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 1199 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 1199 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 1199 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 7770,
        kms: "1000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 22499,
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 22,
    name: "Xpulse hero",
    category: "bikes, Xpulse, hero",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/xpulse-hero.jpg",
    filter: "bike",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 1199, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 1199 * 2, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 1199 * 3, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 1199 * 4, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 1199 * 5, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 7770,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 22499,
        kms: "2,000 Kms",
      },
    ],
  },
]

